import { datadogRum } from '@datadog/browser-rum';
import jwtDecode from 'jwt-decode';
import { useEffect } from 'react';

import { accessTokenKey } from '~/constants/local_storage';

export function useDatadogSetUser() {
  const accessToken =
    typeof window !== 'undefined'
      ? window?.localStorage.getItem(accessTokenKey)
      : undefined;

  const jwtUser =
    !!accessToken &&
    accessToken !== 'undefined' &&
    (jwtDecode(accessToken) as any);

  useEffect(() => {
    if (!jwtUser) return;
    datadogRum.setUser({
      id: jwtUser?.sub,
      name: jwtUser?.prefered_username,
      email: jwtUser?.email,
      roles: jwtUser?.realm_access,
      sonar: jwtUser?.sonar,
    });
  }, [jwtUser]);
}
