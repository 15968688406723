import { ComponentMultiStyleConfig /* theme */ } from '@chakra-ui/react';

export const Popover: ComponentMultiStyleConfig = {
  parts: ['content', 'header', 'popper', 'body', 'closeButton'],
  baseStyle: {
    content: {
      width: 'md',
      padding: '1rem',
    },
    header: {
      fontWeight: 500,
      border: 'none',
      fontSize: '14px',
      fontStyle: 'normal',
      lineHeight: '19.3px',
      padding: '0 0 0.5rem 0',
    },
    popper: {
      borderRadius: '8px',
      border: '1px solid #DEE2E8',
    },
    body: {
      color: '#1C2128',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '18px',
      padding: 0,
    },
    closeButton: {
      margin: '4px',
      color: '#1C2128',
    },
  },
  variants: {
    fullWidth: {
      popper: {
        width: '100%',
      },
    },
  },
};
