// list of errors that can be ignored in Datadog RUM
export const ddIgnorableErrors = [
  'KRI-IGNORE', // Manually thrown ignorable errors
  'Unexpected status code 404: Not found, Device not found', // Related with push notifications permissions not allowed
  'NotAllowedError: Registration failed - permission denied', // Same as above
  'AbortError: Registration failed - permission denied', // Same as above
  'Pusher Beams does not support this browser version (Service Workers not supported)', // Pusher not supported
  'ResizeObserver loop completed with undelivered notifications',
  'Object Not Found Matching Id', // Seems related with Outlook safe link feature
  'Abort fetching component for route', // Thrown by next when a route change is performed before the previous one is finished.
  'Route did not complete loading', // Thrown by next when a route change is performed before the previous one is finished.
  'Uncaught "Object Not Found Matching Id"',
];
