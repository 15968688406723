import { Routes } from '~/types/routes';

export const routes: Routes = {
  foam: {
    app: `${process.env.NEXT_PUBLIC_FOAM_DOMAIN}`,
    resetPassword: '/foam/reset-password',
    forgotPassword: '/foam/forgot-password',
    forgotPasswordEmailSent: '/foam/password-reset-email-sent',
    login: '/foam/login',
    signup: '/foam/signup',
    signUpNameAndAgency: '/foam/signup-name-agency',
    signupAccountType: '/foam/signup-account-type',
    termsAndConditions: `${process.env.NEXT_PUBLIC_FOAM_DOMAIN}/terms`,
    privacyPolicy: `${process.env.NEXT_PUBLIC_FOAM_DOMAIN}/privacy`,
    resetPasswordlinkExpired: '/foam/link-expired',
    logout: '/foam/logout',
    adminInvitationLinkError: '/foam/link-error',
    agencyPendingInvitation: '/foam/pending-invitation',
    chromeExtensionTutorial: `${process.env.NEXT_PUBLIC_FOAM_DOMAIN}/oauth/extension/tutorial`,
    accessBrandDeals: '/foam/access-brand-deals',
    onboardingTalentRep: `${process.env.NEXT_PUBLIC_FOAM_DOMAIN}/welcome`,
  },
  echo: {
    app: `${process.env.NEXT_PUBLIC_ECHO_DOMAIN}`,
    forgotPassword: '/whalar/forgot-password',
    login: '/whalar/login',
    signup: '/whalar/signup',
    signUpNameAndAgency: '/whalar/signup-name-agency',
    forgotPasswordEmailSent: '/whalar/password-reset-email-sent',
    termsAndConditions: `https://whalar.com/terms`,
    privacyPolicy: `https://whalar.com/privacy`,
    signupAccountType: '/whalar/signup-account-type',
    resetPasswordlinkExpired: '/whalar/link-expired',
    verifyEmail: '/whalar/verify-email',
    logout: '/whalar/logout',
  },
  extension: {
    landing: '/extension/landing',
    signup: '/extension/signup',
    login: '/extension/login',
  },
  root: '/',
};
