import {
  ComponentMultiStyleConfig,
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/react';

import { hexToRgbaString } from '~/utils/colors';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers([
    'control',
    'labelTextContainer',
    'root',
    'thumb',
  ]);

const controlStyle = defineStyle({
  minHeight: '1.25rem',
  minWidth: '2.5rem',
  position: 'relative',
  alignItems: 'center',
  borderRadius: 'full',
  transition: 'all 500ms',
  _checked: {
    backgroundColor: 'secondary.500',
    _hover: {
      backgroundColor: 'secondary.600',
    },
    _focusVisible: {
      backgroundColor: 'secondary.600',
    },
    _active: {
      backgroundColor: 'secondary.700',
    },
  },
  _unchecked: {
    backgroundColor: 'primary.100',
    _dark: {
      backgroundColor: 'primary.500',
    },
    _hover: {
      backgroundColor: 'primary.200',
      _dark: {
        backgroundColor: 'primary.400',
      },
    },
    _focus: {
      backgroundColor: 'primary.200',
      _dark: {
        backgroundColor: 'primary.400',
      },
    },
    _active: {
      backgroundColor: 'primary.300',
    },
  },
});

const labelTextContainerStyles = defineStyle({
  display: 'flex',
  flexDirection: 'column',
});

const rootStyle = defineStyle({
  '& > input[type="checkbox"]': {
    clip: 'auto !important',
    margin: '10px !important',
    zIndex: -1,
  },
  display: 'inline-flex',
  alignItems: 'center',
  gap: '0.5rem',
  cursor: 'pointer',
  margin: '1rem 0',
  _disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
});

const thumbStyle = defineStyle({
  boxSize: '1rem',
  position: 'absolute',
  top: '50%',
  display: 'block',
  borderRadius: 'full',
  transform: 'translate(2px, -50%)',
  boxShadow: `0px 2px 8px 0px ${hexToRgbaString('#393e42', 0.2)}`,
  backgroundColor: 'white',
  transition:
    'left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  _checked: {
    transform: 'translate(22px, -50%)',
  },
  _unchecked: {},
});

const baseStyle = definePartsStyle({
  track: controlStyle,
  labelTextContainer: labelTextContainerStyles,
  root: rootStyle,
  thumb: thumbStyle,
});

export const Toggle: ComponentMultiStyleConfig = defineMultiStyleConfig({
  baseStyle,
  variants: {
    default: {},
    full: {
      root: {
        width: '100%',
        justifyContent: 'space-between',
      },
    },
  },
  defaultProps: {
    variant: 'default',
  },
});
