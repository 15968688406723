import { Text } from '@chakra-ui/react';
import { NextRouter } from 'next/router';
import React from 'react';

import { ContactError } from '~/components/ContactError';
import Link from '~/components/Link';
import {
  USER_EXIST_ERROR_TITLE,
  USER_DOES_NOT_EXIST_ERROR_TITLE,
} from '~/constants';
import { routes } from '~/constants/routes';
import { captureException } from '~/errorsLogging';

import { AlertProps } from './useAlert';

export interface AlertError {
  message: string;
  errorCode: number;
}

export const getAlertFeedBackError = (
  error: AlertError,
  setAlertProps: React.Dispatch<React.SetStateAction<AlertProps>>,
  errorInfo?: Record<string, string>
) => {
  const { message } = error;
  const eventId = captureException(new Error(message), {});

  const nameValidationErrors = errorInfo
    ? Object.entries(errorInfo)
        .filter(([_, value]) => Boolean(value))
        .map(([key, _]) => ({
          prefix:
            key === 'managerName'
              ? 'Invalid characters in Manager name'
              : key === 'agencyName'
              ? 'Invalid characters in Agency name'
              : '',
        }))
    : [];

  return setAlertProps({
    isVisible: true,
    title: 'There was an error',
    status: 'error',
    description: (
      <>
        {nameValidationErrors.length > 0
          ? nameValidationErrors.map(({ prefix }, index) => (
              <Text key={index}>{prefix}</Text>
            ))
          : null}
        <ContactError eventId={eventId} />
      </>
    ),
  });
};

export const getAlertEmailStatus = (
  router: NextRouter,
  platform: string,
  setAlertProps: React.Dispatch<React.SetStateAction<AlertProps>>,
  emailExists?: boolean
) => {
  const page = emailExists ? 'login' : 'signup';
  setAlertProps({
    title: emailExists
      ? USER_EXIST_ERROR_TITLE
      : USER_DOES_NOT_EXIST_ERROR_TITLE,
    isVisible: true,
    status: 'error',
    description: (
      <>
        <Text textAlign="start">
          Please {''}
          <Link
            onClick={() => {
              router.push(routes[platform][page]);
            }}
            fontWeight="medium"
            textDecoration="underline"
          >
            {page}
          </Link>
          {''} or use a different email address
        </Text>
      </>
    ),
  });
};
