import { Icon, IconProps } from '@chakra-ui/react';

/**
 * TODO: In the future this should be updated in the YoutubeIcon
 * Note: This is used in the ManagedCreatorCard, we need a new
 * IconBox component to simulate the Icon styling in ManagedCreatorCard
 * and unify all social icons with this style.
 */
export const YoutubeNoBorderIcon: React.FC<IconProps> = props => (
  <Icon
    data-testid="youtube-icon"
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    {...props}
  >
    <path
      d="M30.3469 7.73164C30.1772 7.09341 29.843 6.51092 29.3775 6.04246C28.9121 5.574 28.3317 5.23602 27.6946 5.06232C25.3491 4.43164 15.9741 4.43164 15.9741 4.43164C15.9741 4.43164 6.59912 4.43164 4.25367 5.06232C3.61653 5.23602 3.0362 5.574 2.57073 6.04246C2.10527 6.51092 1.771 7.09341 1.60139 7.73164C0.974121 10.0873 0.974121 14.9998 0.974121 14.9998C0.974121 14.9998 0.974121 19.9123 1.60139 22.268C1.771 22.9062 2.10527 23.4887 2.57073 23.9572C3.0362 24.4256 3.61653 24.7636 4.25367 24.9373C6.59912 25.568 15.9741 25.568 15.9741 25.568C15.9741 25.568 25.3491 25.568 27.6946 24.9373C28.3317 24.7636 28.9121 24.4256 29.3775 23.9572C29.843 23.4887 30.1772 22.9062 30.3469 22.268C30.9741 19.9123 30.9741 14.9998 30.9741 14.9998C30.9741 14.9998 30.9741 10.0873 30.3469 7.73164Z"
      fill="#FF0302"
    />
    <path
      d="M12.9058 19.4607V10.5391L20.7467 14.9999L12.9058 19.4607Z"
      fill="#FEFEFE"
    />
  </Icon>
);
