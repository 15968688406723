var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/base.ts
import axios from "axios";
var instance = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_FOAM_API_RESOURCE}/api`
});
function request(method, url, data, axiosConfig) {
  return __async(this, null, function* () {
    const response = yield instance[method](
      url,
      method === "get" ? __spreadProps(__spreadValues({}, axiosConfig), {
        params: data
      }) : data,
      method !== "get" ? axiosConfig : void 0
    );
    return response.data;
  });
}
var requests = {
  get(url, data) {
    return __async(this, null, function* () {
      return request("get", url, data);
    });
  },
  post(url, data, axiosConfig) {
    return __async(this, null, function* () {
      return request("post", url, data, axiosConfig);
    });
  }
};

// src/constants/routes.ts
var routes = {
  validate: {
    managers: "validate/managers/names"
  },
  users: {
    settings: "user/settings"
  },
  agencies: {
    disableAutoSubmitBrandDeals: "agencies/disable-auto-submit-brand-deals"
  }
};

// src/index.ts
var ApiBase = {
  validate: {
    managers: (managerName, agencyName) => {
      return requests.get(routes.validate.managers, {
        managerName,
        agencyName
      });
    }
  },
  users: {
    settings: () => requests.get(
      routes.users.settings
    )
  },
  agencies: {
    disableAutoSubmitBrandDeals: (accessToken) => {
      return requests.post(
        routes.agencies.disableAutoSubmitBrandDeals,
        void 0,
        {
          headers: {
            "X-AUTH-TOKEN": accessToken
          }
        }
      );
    }
  }
};
export {
  ApiBase
};
