import { USER_ROLES } from '~/constants/user_roles';

export const getDefaultAppDomain = (role: USER_ROLES) => {
  switch (role) {
    case USER_ROLES.backofficeAdmin:
    case USER_ROLES.backofficeWriter:
    case USER_ROLES.backofficeReader:
      return `${process.env.NEXT_PUBLIC_BACKOFFICE_DOMAIN}`;
    case USER_ROLES.agency:
    case USER_ROLES.brand:
    case USER_ROLES.brandUser:
      return `${process.env.NEXT_PUBLIC_ORCA_DOMAIN}`;
    case USER_ROLES.creatorEcho:
    case USER_ROLES.creatorFoam:
    case USER_ROLES.creatorManaged:
    case USER_ROLES.talentRep:
    case USER_ROLES.whalarMode:
    default:
      return `${process.env.NEXT_PUBLIC_FOAM_DOMAIN}`;
  }
};
