import { Icon, IconProps } from '@chakra-ui/react';

/**
 * TODO: In the future this should be updated in the TiktokIcon
 * Note: This is used in the ManagedCreatorCard, we need a new
 * IconBox component to simulate the Icon styling in ManagedCreatorCard
 * and unify all social icons with this style.
 */
export const TiktokNoBorderIcon: React.FC<IconProps> = props => (
  <Icon
    data-testid="tiktok-icon"
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.29479 24.5791C1.86902 20.0968 5.37819 10.1171 13.7196 11.3727V12.297C6.67489 11.985 3.57389 19.985 7.29479 24.5791ZM22.5099 7.2669C23.367 7.91893 24.549 8.3999 26.1784 8.4917V9.39718C24.3753 9.08015 23.2325 8.22712 22.5099 7.2669ZM20.248 2.5C20.2468 2.83414 20.2923 3.19308 20.3498 3.52095H17.0245V19.2834C17.0245 20.0101 16.9461 20.657 16.7898 21.2246C15.4356 24.0662 11.6015 23.695 10.5419 21.7449C12.1192 22.6852 14.7129 22.4355 15.7775 20.2037C15.9327 19.6372 16.0111 18.9889 16.0111 18.2624V2.5H20.2483H20.248Z"
      fill="#00F7EF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.2614 3.521V3.54124C21.2626 3.90617 21.3749 9.1834 27.1921 9.51269C27.1921 14.4221 27.1948 9.51269 27.1948 13.5902C26.7579 13.615 23.3658 13.3815 21.2551 11.5891L21.2486 19.5269C21.3007 23.1212 19.199 26.6462 15.2643 27.3564C14.1619 27.5551 13.1692 27.5776 11.5029 27.2309C1.90377 24.4974 5.09341 10.9433 14.7318 12.3939C14.7318 16.7697 14.7345 12.3928 14.7345 16.7697C10.7528 16.212 9.42073 19.3655 10.4791 21.6232C11.4421 23.6788 15.4065 24.1247 16.7895 21.2247C16.9461 20.657 17.0243 20.0099 17.0243 19.2834V3.521H21.2614Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7191 12.297C14.0476 12.3107 14.3857 12.3415 14.7316 12.3936C14.7316 16.7694 14.7343 12.3925 14.7343 16.7694C10.7525 16.2117 9.42047 19.3652 10.4788 21.623C10.4983 21.664 10.5192 21.7051 10.5414 21.7447C10.0679 21.4627 9.687 21.0753 9.46539 20.602C8.40857 18.344 9.73911 15.1908 13.7208 15.7482C13.7208 11.7376 13.7197 15.075 13.7197 12.2967L13.7191 12.297ZM26.1779 9.39723C26.4947 9.45282 26.8328 9.49245 27.1916 9.51269C27.1916 14.4221 27.1943 9.51269 27.1943 13.5902C26.7573 13.615 23.3652 13.3815 21.2546 11.5891L21.248 19.5269C21.3001 23.1212 19.1984 26.6462 15.2637 27.3564C14.1614 27.5551 13.1686 27.5776 11.5024 27.2309C9.62651 26.6969 8.24117 25.7481 7.29395 24.5792C8.13785 25.2771 9.19707 25.8422 10.4902 26.2111C12.1546 26.5564 13.1477 26.5341 14.25 26.3351C18.1847 25.6246 20.2864 22.0999 20.2355 18.5068L20.2409 10.5679C22.3515 12.3603 25.7436 12.5949 26.1821 12.5687C26.1821 8.78198 26.1782 12.7452 26.1782 9.39694L26.1779 9.39723ZM21.2609 3.521V3.54124C21.2609 3.75592 21.3013 5.66125 22.5094 7.26695C21.0533 6.1579 20.5344 4.55592 20.349 3.521H21.2609Z"
      fill="#FF004F"
    />
  </Icon>
);
