import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { ReCaptchaProvider } from 'next-recaptcha-v3';
import { FC, useMemo } from 'react';

import { HeapIdentifier } from '~/components/HeapIdentifier';
import { PLATFORMS } from '~/constants/platforms';
import { IntercomProvider } from '~/contexts/Intercom/IntercomContext';
import { AlertProvider } from '~/contexts/alert';
import { AuthProvider } from '~/contexts/auth';
import { SignUpProvider } from '~/contexts/signup';
import { useDatadogSetUser } from '~/hooks/useDatadogSetUser';
import { useSentrySetUser } from '~/hooks/useSentrySetUser';
import customTheme from '~/styles/theme';
import { ChildrenType } from '~/types';

import { GlobalErrorBoundary } from '../GlobalErrorBoundary';

const AppProviders: FC<ChildrenType> = ({ children }) => {
  const { pathname } = useRouter();
  const queryClient = new QueryClient();

  useSentrySetUser();
  useDatadogSetUser();

  const platform = useMemo(() => {
    const subpath = pathname?.split('/')[1];
    return subpath === 'whalar' ? PLATFORMS.echo : PLATFORMS.foam;
  }, [pathname]);

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={customTheme}>
        <IntercomProvider>
          <GlobalErrorBoundary>
            <ReCaptchaProvider
              reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY}
            >
              <AlertProvider>
                {platform && (
                  <AuthProvider platform={platform}>
                    <HeapIdentifier />
                    <SignUpProvider>{children}</SignUpProvider>
                  </AuthProvider>
                )}
              </AlertProvider>
            </ReCaptchaProvider>
          </GlobalErrorBoundary>
        </IntercomProvider>
      </ChakraProvider>
    </QueryClientProvider>
  );
};

export default AppProviders;
