import jwtDecode from 'jwt-decode';
import { useEffect } from 'react';

import { accessTokenKey } from '~/constants/local_storage';
import { Sentry } from '~/errorsLogging';

export function useSentrySetUser() {
  const accessToken =
    typeof window !== 'undefined'
      ? window?.localStorage.getItem(accessTokenKey)
      : undefined;

  const jwtUser =
    !!accessToken &&
    accessToken !== 'undefined' &&
    (jwtDecode(accessToken) as any);

  useEffect(() => {
    if (!jwtUser) return;
    Sentry.setUser({
      id: jwtUser?.sub,
      email: jwtUser?.email,
      ip_address: '{{auto}}',
    });
  }, [jwtUser?.sub, jwtUser?.email]);
}
