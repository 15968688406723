import 'focus-visible';
import '~/styles/nprogress.css';
import '~/styles/styles.fonts.css';
import 'react-dates/lib/css/_datepicker.css';
import '~/styles/react-dates-overrides.css';
import '~/styles/intercom.css';
import 'react-dates/initialize';
import moment from 'moment';
import type { AppProps } from 'next/app';
import Router from 'next/router';
import NProgress from 'nprogress';
import React from 'react';

import AppProviders from '~/components/AppProviders';
import Head from '~/components/Head';

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

// Only set up NProgress events if NOT in Cypress
if (typeof window !== 'undefined' && !window.Cypress) {
  Router.events.on('routeChangeStart', () => {
    NProgress.start();
    //@ts-ignore
    document.body.style = 'cursor: progress';
  });

  Router.events.on('routeChangeComplete', () => {
    NProgress.done();
    //@ts-ignore
    document.body.style = 'cursor: initial';
  });

  Router.events.on('routeChangeError', () => {
    NProgress.done();
    //@ts-ignore
    document.body.style = 'cursor: initial';
  });
}

export default function App({ Component }: AppProps) {
  React.useEffect(() => {
    // Hide recaptcha elements when running in cypress
    if (typeof window !== 'undefined' && window.Cypress) {
      const style = document.createElement('style');
      style.innerHTML = `
        .rc-anchor, 
        .grecaptcha-badge,
        [id^="g-recaptcha"] {
          display: none;
        }
        img {
          visibility: hidden !important;
          opacity: 0 !important;
        }
      `;
      document.head.appendChild(style);
    }
  }, []);

  return (
    <AppProviders>
      <Head />
      <Component />
    </AppProviders>
  );
}
//@ts-ignore
export function reportWebVitals({ id, name, label, value }): void {
  //eslint-disable-next-line no-unused-expressions
  //@ts-ignore
  window.ga?.('gtm2.send', 'event', {
    eventCategory:
      label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    eventAction: name,
    eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    eventLabel: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate.
  });
}
