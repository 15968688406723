import { ComponentStyleConfig } from '@chakra-ui/react';

export const Button: ComponentStyleConfig = {
  variants: {
    // The font color is different from stroke
    'no-background': {
      backgroundColor: 'transparent',
      borderColor: 'primary.600',
      borderWidth: 1,
      color: 'primary.500',
      _hover: {
        color: 'primary.600',
      },
    },
    disabled: {
      backgroundColor: 'primary.100',
      color: 'white',
    },
    primary: {
      backgroundColor: 'primary.500',
      borderColor: 'primary.500',
      borderWidth: 1,
      color: 'white',
      _hover: {
        backgroundColor: 'primary.600',
        borderColor: 'primary.600',
      },
    },
    tertiary: {
      backgroundColor: 'primary.50',
      borderColor: 'primary.50',
      borderWidth: 1,
      color: 'primary.500',
      _hover: {
        backgroundColor: 'primary.100',
        borderColor: 'primary.100',
      },
      _pressed: {
        backgroundColor: 'primary.200',
        borderColor: 'primary.200',
      },
    },
  },
  sizes: {
    md: {
      minHeight: 10,
      minWidth: 10,
      height: 10,
      fontSize: 'md',
      paddingX: 6,
    },
  },
};
