export const hexToRgb = (
  hex: string
): { r: number; g: number; b: number } | null => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const hexToRgbaString = (hex: string, opacity = 1): string | null => {
  const rgb = hexToRgb(hex);

  if (!rgb) {
    return null;
  }

  const { r, g, b } = rgb;

  return `rgba(${r},${g},${b},${opacity})`;
};
